import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';
import YfirlitGraphView from './YfirlitGraphView';

import config from './../config';

function YfirlitView(props) {
	return <React.Fragment>
		{
			<Tab visible={props.selectedTab == props.dataType}
				tabColor={props.tabColor}
				heading="Yfirlit"
				helpContent={
					<>
						<Link to="/s/hjalp#yfirlit">Yfirlit</Link>
						<Link to="/s/hjalp#flettur">Um flettur</Link>
					</>
				}>
				{
					props.fldata &&
					<React.Fragment>
						<p>Lýsing flettunnar er fólgin í því sem fram kemur undir flipunum hér fyrir ofan.<br/>
							Fletturnar venslast hver við aðra á ýmsan hátt og oft í ólíkum venslategundum eins og yfirlitið sýnir.</p>

						<YfirlitGraphView flid={props.fldata.flid} />

						{/*<pre>
							{JSON.stringify(props.talningData, undefined, 2)}
						</pre>
						<pre>
							{JSON.stringify(props.fldata, undefined, 2)}
						</pre>
						*/}

					</React.Fragment>
				}
			</Tab>
		}
	</React.Fragment>;
}


export default YfirlitView;
