import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function Tab(props) {
	return 	<div className={'card'+(!props.visible ? ' d-none' : '')} style={{marginTop: -1}}>
		<div className="card-body">
			<div className="tab-heading" style={props.tabColor ? { backgroundColor: props.tabColor } : null}>
				<h3>{props.heading || ''}
					{
						props.description &&
						<React.Fragment>
							<br/>
							<small className="heading-content">{props.description}</small>
						</React.Fragment>
					}
					{
						props.extraContent &&
						<>
							<br/>
							<small className="smaller heading-content">{props.extraContent}</small>
						</>
					}
				</h3>
				{
					props.helpContent &&
					<div className="tab-help d-flex justify-content-center align-items-center">
						<div className="help-content d-flex justify-content-center align-items-center">
							<strong>?</strong>
							<div>
								{props.helpContent}
							</div>
						</div>
					</div>
				}
			</div>
			{props.children}
		</div>
	</div>;
}


export default Tab;
