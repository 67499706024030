import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useRouteMatch, useParams } from "react-router-dom";

import SearchBox from './components/SearchBox';
import SearchResultsList from './components/SearchResultsList';
import FlettuView from './components/FlettuView';
import Forsida from './components/Forsida';
import StickyWatcher from './components/StickyWatcher';
import StadtolurView from './components/StadtolurView';
import FlettaDagsins from './components/FlettaDagsins';
import PageView from './components/PageView';
import PageMenu from './components/PageMenu';
import SiteFooter from './components/SiteFooter';
import GoogleAnalytics from './components/GoogleAnalytics';

import logo from './img/logo-single.png';

const App = props => {
	const router = useRef(null);

	const [searchFormSticky, setSearchFormSticky] = useState(false);

	useEffect(() => {
		router.current.history.listen(function(event) {
			document.body.classList.remove('index-list-open');
		});
	});

	return (
		<Router ref={router}>
			<div className="header">

				<div className="logo-wrapper">
					<div className="logo">
						<img src={logo} className="mr-4" />
					</div>
					
					<div className="text">
						<div>Íslenskt orðanet</div>
					</div>

				</div>

				<PageMenu />

			</div>


			<StickyWatcher tagName="div"
				className="search-container"
				stickyClassName="sticky"
				onStick={function() {
					setSearchFormSticky(true);
				}.bind(this)}
				onUnstick={function() {
					setSearchFormSticky(false);
				}.bind(this)}
			>
				<SearchBox />
			</StickyWatcher>

			<Route exact path="/">
				<PageView url="/">
					<FlettaDagsins />
				</PageView>

				<StadtolurView summary={true} />
				<SiteFooter />
			</Route>

			<Route exact path="/stadtolur">
				<StadtolurView />
				<SiteFooter />
			</Route>

			<Route exact path={'/s/:path'} render={(routeProps) => (
				<React.Fragment>
					<PageView />
					<SiteFooter />
				</React.Fragment>
			)} />

			<div className="row mt-0" style={{minHeight: '100vh'}}>

				<div className={'col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 hidden-print index-column pr-0'+(searchFormSticky ? ' searchform-sticky' : '')}>
					<Route path={[
						"/leit/:leitarOrd",
						"/fletta/:tab/:querystring",
						"/fletta/:tab/:querystring/:urlauki",
						"/fletta/:tab/:querystring/:urlauki+"
					]}>
						<SearchResultsList />
					</Route>
				</div>

				<Switch>
					<Route
						exact
						push={false}
						path="/fletta/:id"
						render={(props) => (
							<Redirect to={`/fletta/yfirlit/${props.match.params.id}`} />
						)}
					/>
					<Route path="/fletta/:tab/:querystring/:urlauki+">
						<div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 main-content-wrapper">
							<FlettuView />
						</div>
					</Route>
					<Route path="/fletta/:tab/:querystring/:urlauki">
						<div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 main-content-wrapper">
							<FlettuView />
						</div>
					</Route>
					<Route path="/fletta/:tab/:querystring">
						<div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 main-content-wrapper">
							<FlettuView />
						</div>
					</Route>
				</Switch>

			</div>

			<GoogleAnalytics />

		</Router>
	)
}

export default App;
