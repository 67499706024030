import React, { Component } from 'react';
import { Link, NavLink, withRouter } from "react-router-dom";
import _ from 'underscore';
import TengingarView from './TengingarView';
import HugtokView from './HugtokView';
import PorView from './PorView';
import GrannheitiView from './GrannheitiView';
import SkyldheitiView from './SkyldheitiView';
import YfirlitView from './YfirlitView';
import MetinVenslView from './MetinVenslView';
import SamsetningarView from './SamsetningarView';
import OsambView from './OsambView';
import SetningargerdView from './SetningargerdView';
import SiteFooter from './SiteFooter';

import config from './../config';
import utils from './../utils';

class FlettuView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: 'yfirlit',
			found: true
		};
	}

	componentDidMount() {
		if (this.props.match.params.querystring) {
			this.fetchData();
		}

		if (this.props.match.params.tab) {
			this.setState({
				selectedTab: this.props.match.params.tab
			});
		}

		if (this.props.location.search) {
			this.setState({
				searchString: this.props.location.search
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.querystring != this.props.match.params.querystring ||
			prevProps.match.params.urlauki != this.props.match.params.urlauki) {
			this.fetchData();
		}
		if (this.props.match.params.tab && this.props.match.params.tab != prevProps.match.params.tab) {
			this.setState({
				selectedTab: this.props.match.params.tab
			});
		}
		if (this.props.location.search != prevProps.location.search) {
			this.setState({
				searchString: this.props.location.search
			});
		}
	}

	fetchData() {
		window.scrollTo(0, 0);

		this.setState({
			talningData: null
		});

		let query = 'querystring='+encodeURIComponent(this.props.match.params.querystring);
		if (!isNaN(this.props.match.params.querystring)) {
			query = 'flid='+this.props.match.params.querystring;
		}

		fetch(config.apiRoot+'/api/flettur_leit/?'+query+
				(this.props.match.params.urlauki ? '&urlauki=/'+this.props.match.params.urlauki : '')
			)
			.then((result) => result.json())
			.then((json) => {
				if (json.results[0]) {
					if (!isNaN(this.props.match.params.querystring)) {
						this.props.history.replace('/fletta/'+(this.props.match.params.tab || 'yfirlit')+'/'+json.results[0].querystring+(json.results[0].urlauki ? json.results[0].urlauki : ''));
					}

					this.fetchTalning(json.results[0].flid);
					this.setState({
						flettuData: json.results[0],
						flid: json.results[0].flid,
						found: true
					});

					window.document.title = config.siteTitle+' | '+json.results[0].fletta;
				}
				else {
					this.setState({
						flettuData: null,
						found: false
					});
				}
			});
	}

	fetchTalning(flid) {
		fetch(config.apiRoot+'/api/talning/?flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					talningData: json.results[0]
				});
			});
	}

	displayNum(number, singular, plural) {
		return number.toString().endsWith('1') && number != 11 ? singular : plural;
	}

	lightenColor(hex) {
		var c;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
			c = hex.substring(1).split('');
			if (c.length== 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x'+c.join('');
			return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.7)';
		}
		return null;
	}

	render() {
		let tabs = [];

		if (this.state.talningData) {
			tabs.push(<YfirlitView key="yfirlit"
				tabColor="#772222"
				dataType="yfirlit"
				title="Yfirlit"
				selectedTab={this.state.selectedTab}
				fldata={this.state.flettuData}
				talningData={this.state.talningData} />)

			if (this.state.talningData.fjht1 > 0) {
				tabs.push(<HugtokView key="hugtok"
					dataType="hugtok"
					tabColor="#719f77"
					selectedTab={this.state.selectedTab}
					title={this.state.talningData.fjht1+' vensl gegnum hugtök'}
					flid={this.state.flid} />
				);
			}

			if (this.state.talningData.fjpor > 0) {
				tabs.push(<PorView key="por"
					dataType="por"
					tabColor="#5681a5"
					selectedTab={this.state.selectedTab}
					title={this.state.talningData.fjpor+' '+this.displayNum(this.state.talningData.fjpor, 'par', 'pör')}
					flid={this.state.flid} />
				);
			}

			if (this.state.talningData.fjskyld > 0) {
				tabs.push(<SkyldheitiView key="skyldheiti"
					tabColor="#6a96a9"
					dataType="skyldheiti"
					selectedTab={this.state.selectedTab}
					title={this.state.talningData.fjskyld+' skyldheiti'}
					flid={this.state.flid} />
				);
			}

			if (this.state.talningData.fjgrann > 0) {
				tabs.push(<GrannheitiView key="grannheiti"
					tabColor="#be884b"
					dataType="grannheiti"
					selectedTab={this.state.selectedTab}
					title={this.state.talningData.fjgrann+' grannheiti'}
					talningData={this.state.talningData}
					flid={this.state.flid} />
				);
			}

			if (this.state.talningData.fjsamhe > 0 || this.state.talningData.fjand > 0 || this.state.talningData.fjstikl > 0) {
				tabs.push(<MetinVenslView key="metin_vensl"
					tabColor="#4d9e8e"
					dataType="metin_vensl"
					selectedTab={this.state.selectedTab}
					title={(this.state.talningData.fjsamhe+this.state.talningData.fjand+this.state.talningData.fjstikl)+' metin vensl'}
					talningData={this.state.talningData}
					flid={this.state.flid} />
				);
			}

			tabs.push(<TengingarView key="samanburdur"
				tabColor="#888"
				dataType="samanburdur"
				selectedTab={this.state.selectedTab}
				title={'Samanburðarfletta'}
				searchString={this.state.searchString}
				fldata={this.state.flettuData} />
			);

			if (this.state.talningData.fjosamb > 0) {
				tabs.push(<OsambView key="osamb"
					tabColor="#7d6c8d"
					dataType="osamb"
					selectedTab={this.state.selectedTab}
					title={this.state.talningData.fjosamb+' '+this.displayNum(this.state.talningData.fjosamb, 'orðastæða', 'orðastæður')}
					flid={this.state.flid} />
				);
			}

			if (this.state.talningData.fjsams > 0) {
				tabs.push(<SamsetningarView key="samsett"
					tabColor="#bda34e"
					dataType="samsett"
					selectedTab={this.state.selectedTab}
					title={this.state.talningData.fjsams+' '+this.displayNum(this.state.talningData.fjsams, 'samsetning', 'samsetningar')}
					flid={this.state.flid} />
				);
			}

			if (this.state.flettuData && this.state.flettuData.ordafjoldi > 1) {
				tabs.push(<SetningargerdView key="setningargerd"
					tabColor="#475577"
					dataType="setningargerd"
					selectedTab={this.state.selectedTab}
					title={'Setningargerð'}
					mark={this.state.flettuData.mark}
					ofl_texti={this.state.flettuData.ofl_texti} />
				);
			}
		}

		if (tabs.length > 0 && _.pluck(tabs, 'key').indexOf(this.state.selectedTab) == -1) {
			this.props.history.push('/fletta/yfirlit/'+this.props.match.params.querystring+(this.props.match.params.urlauki ? '/'+this.props.match.params.urlauki : ''));
		}

		return  <div className="main-content">
			{
				this.state.flettuData &&
				<React.Fragment>

					<div className="flettu-head">

						<button className="index-list-button" onClick={() => {
							if (document.body.classList.contains('index-list-open')) {
								document.body.classList.remove('index-list-open');
							}
							else {
								document.body.classList.add('index-list-open');
							}
						}}>
							<div className="hamburger-icon black">
								<div className="icon-middle" />
								<div className="icon-before-after" />
							</div>
							<span>Opna leitarniðurstöður</span>
						</button>

						<h2>{this.state.flettuData.fletta} {/*utils.displayOfl(this.state.flettuData.ofl) && <small className="color-orange">{this.state.flettuData.ofl} </small>}{this.state.flettuData.rnum > 0 && <small>{this.state.flettuData.rnum}</small>*/}{
							this.state.flettuData.greining &&
							<React.Fragment>
								<small className="color-blue"><em>&nbsp;{this.state.flettuData.greining}</em></small>
							</React.Fragment>
						} {/*<a className="relationship-button"></a>*/}</h2>
						<p className="color-gray"><strong dangerouslySetInnerHTML={{__html: this.state.flettuData.ofl_texti}} /></p>
					</div>

					<div className="tabs-wrapper">
						<ul className="nav nav-tabs">
							{
								tabs.map(function(child, index) {
									if (child && child.props.title) {
										return <li key={index} className="nav-item">
											<Link to={'/fletta/'+child.props.dataType+'/'+this.props.match.params.querystring+(this.props.match.params.urlauki ? '/'+this.props.match.params.urlauki : '')}
												className={'nav-link '+(this.state.selectedTab == child.props.dataType ? 'active' : '')}
												style={{ backgroundColor: this.state.selectedTab == child.props.dataType ? child.props.tabColor : this.lightenColor(child.props.tabColor) }}
											>{child.props.title}</Link>
										</li>;
									}
								}.bind(this))
							}
						</ul>
						{tabs}
					</div>

					<SiteFooter />

				</React.Fragment>
			}
			{
				!this.state.found &&
				<p className="lead mt-4 ml-4 mr-4 mb-4"><strong>Þessi fletta fannst ekki.</strong></p>
			}
		</div>;
	}
}


export default withRouter(FlettuView);
