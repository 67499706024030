import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import FrontPage from './FrontPage';
import config from './../config';

class PageView extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/vefur/api/pages/';

		this.htmlContentClickHandler = this.htmlContentClickHandler.bind(this);

		this.state = {
			initialized: false
		};
	}

	componentDidMount() {
		if ((this.props.match && this.props.match.params.path) || this.props.url) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Athuga hvort breytur hafi breyst og hvort component eigi að uppfærast
		if (this.props.match.params.path != prevProps.match.params.path) {
			this.setState({
				data: null,
				willRefresh: true
			}, function() {
				this.fetchData();
			}.bind(this));
		}

		if (!this.state.willRefresh && this.refs.htmlContainer) {
			let tables = this.refs.htmlContainer.getElementsByTagName('table');

			for (var i = 0; i < tables.length; i++) {
				tables[i].classList.add('table');
				tables[i].classList.add('table-responsive');
			}
		}
	}

	isExternal(url) {
		var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
		if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol) return true;
		if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[window.location.protocol]+")?$"), "") !== window.location.host) return true;

		return false;
	}

	scrollToAnchor(anchorName) {
		console.log(anchorName)

//		anchorName = anchorName.substr(1);
	}

	htmlContentClickHandler(event) {
		let linkEl = event.target.closest('a');

		if (linkEl && linkEl.getAttribute('href')) {
			let linkUrl = linkEl.getAttribute('href');

			if (linkUrl.substr(0, 1) == '#') {
				this.scrollToAnchor(linkUrl);
			}
			else if (!this.isExternal(linkUrl) && (!linkEl.getAttribute('target'))) {
				event.preventDefault();
				this.props.history.push(linkUrl);
			}
		}
	}

	fetchData() {
		window.scrollTo(0, 0);

		let path = this.props.url ? this.props.url : this.props.location.pathname;

		// Sæki gögn til APA
		fetch(this.url+'?url='+path)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					data: json.results[0],
					willRefresh: false,
					notFound: json.length == 0
				}, () => {
					this.setState({
						initialized: true
					});

					console.log(this.props.location.hash)

					if (this.props.location && this.props.location.hash && this.props.location.hash.length > 0 && document.getElementById(this.props.location.hash.replace('#', ''))) {
						let element = document.getElementById(this.props.location.hash.replace('#', ''));

						let top = element.offsetTop;

						window.scrollTo(0, top-135);

						if (element.nextSibling && element.nextSibling.tagName && element.nextSibling.tagName.toLowerCase().substr(0, 1) == 'h') {
							element.nextSibling.classList.add('highlight');
						}
					}
				});

				if (json.results[0] && json.results[0].title) {
					window.document.title = config.siteTitle+' | '+json.results[0].title;
				}
			}.bind(this));
	}


	formatHtml(html) {
		try {
			let formatedHtml = html.replace(/\\r|\\n/g, '');

			return formatedHtml;
		}
		catch (e) {
			return '';
		}
	}

	render() {
		let dataItem = this.state.data || null;

		return (
			<div className={'content-container manual-init'+(this.state.initialized ? ' initialized' : '')}>
				{
					dataItem &&

					<div className="box">
						{
							dataItem.url == '/' &&
							<FrontPage />
						}
						<div className="html-content" ref="htmlContainer" onClick={this.htmlContentClickHandler} dangerouslySetInnerHTML={{__html: this.formatHtml(dataItem.content)}}/>

						{
							this.props.children
						}
					</div>
				}
				{
					this.state.notFound &&
					<div className="box">
						<h2>Síða finnst ekki</h2>
						<p>Engin síða finnst á slóðinni <strong>{this.props.history.location.pathname}</strong>.</p>
					</div>
				}
			</div>
		);
	}
}

export default withRouter(PageView);
