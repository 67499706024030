import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';
import AutocompleteInput from './AutocompleteInput';

import GraphView from './GraphView';

import config from './../config';
import utils from './../utils';

class TengiflettuLeit extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div className={'tengiflettuleit'}>

			{
				this.props.tengifletta &&
				<React.Fragment>
					<div className="tengifletta">
						{this.props.tengifletta.fletta}
						{
							this.props.tengifletta.greining &&
							<small className="color-blue">&nbsp;<em>{this.props.tengifletta.greining}</em></small>
						}
					</div>
				</React.Fragment>
			}
			{
				!this.props.tengifletta &&
				<div className="tengifletta"><em>Veldu flettu í leitarreit hér að ofan</em></div>
			}
		</div>;
	}
}

class TengingarView extends Component {
	constructor(props) {
		super(props);

		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.formatAutocompleteListLabel = this.formatAutocompleteListLabel.bind(this);
		this.searchBoxItemSelectHandler = this.searchBoxItemSelectHandler.bind(this);
		this.inputFocusHandler = this.inputFocusHandler.bind(this);

		this.relationTypes = {
			'PAR': 'Parasambönd',
			'SKYLDHEITI': 'Skyldheiti',
			'GRANNHEITI': 'Grannheiti',
			'HUGTAK': 'Hugtök',
		}

		this.state = {
			graphData: {},
			tengifletta: null,
			selectedMethod: 'PAR',
			searchData: null,
			waiting: false
		};
	}

	inputChangeHandler(event) {
		var stateObj = {};

		stateObj[event.target.name] = event.target.value;

		this.setState(stateObj);
	}

	searchBoxItemSelectHandler(item) {
		this.setState({
			tengifletta: item,
			inputVisible: false
		}, () => {
			this.fetchData(this.props.fldata.flid, this.state.tengifletta.flid);
		});

		this.wordSearch(item.fletta, true)
	}

	inputFocusHandler() {
		this.refs.searchInput.refs.inputField.select();
	}

	executeSearch() {
		//this.props.history.push('/leit/'+this.state.searchBoxInput);
	}

	formatAutocompleteListLabel(item) {
		let labelHtml = item.fletta.escape()+', <small class="color-orange">'+item.ofl+'</small>'+(item.greining ? '<small class="color-blue">&nbsp;<em>'+item.greining+'</em></small>' : '');

		return labelHtml;
	}

	fetchData(flid1, flid2) {
		this.setState({
			waiting: true
		});

		fetch(config.neo4jApi+'/tengingar/?flid1='+flid1+'&flid2='+flid2+'&relationship='+this.state.selectedMethod)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json),
					waiting: false
				});
			});
	}

	wordSearch(fletta, disableAutoFetch) {
		this.setState({
			searchData: null
		});

		fetch(config.apiRoot+'/api/flettur_leit/?leitarstrengur='+fletta)
			.then((res) => res.json())
			.then((json) => {
				this.setState({
					searchData: json.results,
					tengifletta: json.results.length > 0 ? json.results[0] : null
				}, () => {
					if (this.state.tengifletta && !disableAutoFetch) {
						this.fetchData(this.props.fldata.flid, this.state.tengifletta.flid);
					}
				});
			});
	}

	render() {
		return <React.Fragment>
			{
				this.props.fldata &&
				<Tab visible={this.props.selectedTab == this.props.dataType}
					tabColor={this.props.tabColor}
					heading="Samanburðarfletta"
					description="Vensl tveggja flettna í gegnum parasambönd, grannheiti eða skyldheiti."
					helpContent={
						<>
							<Link to="/s/hjalp#samanburdur">Um samanburðarflettur</Link>
						</>
					}>

					<p className="col-8">Hér er hægt að skoða vensl milli tveggja flettna í gegnum parasambönd, grannheiti, skyldheiti eða í gegnum hugtök. Til að skoða venslin þarf að byrja á því að leita að samanburðarflettunni í leitarreit hér fyrir neðan.</p>

					<div className="card bg-light mb-4">
						<div className="form card-body">

							<div className="row mt-0 mb-0">
								<div className="form-group col">
									<label>Fletta til samanburðar:</label>
									<AutocompleteInput inputClassName="form-control"
										ref="searchInput"
										responseDataField="results"
										searchUrl={config.apiRoot+'/api/sprettileit/?fletta=$s'}
										onChange={this.inputChangeHandler}
										inputName="searchBoxInput"
										value={this.state.searchBoxInput}
										type="text"
										onEnter={(event) => {
											this.wordSearch(this.state.searchBoxInput)
										}}
										onItemSelect={this.searchBoxItemSelectHandler}
										placeholder="Leit að flettu til samanburðar"
										minChars={2}
										valueField={'fletta'}
										listLabelFormatFunc={this.formatAutocompleteListLabel}
										selectedItemClass="active"
										onFocus={this.inputFocusHandler}
										disableAutoFill={false}
										headerText="Ýttu á enter eða veldu orð úr lista til að sjá vensl á milli orðanna tveggja."
										/>
										<button className="btn btn-primary mt-2" onClick={() => {
											this.wordSearch(this.state.searchBoxInput)
										}}>Leita að flettu</button>
									{/*
									<input type="text" className="form-control"
										name="searchBoxInput"
										onChange={this.inputChangeHandler}
										placeholder="Leit að flettu til samanburðar"
										onKeyDown={(event) => {
											if (event.keyCode == 13) {
												this.wordSearch(event.target.value)
											}
										}}
									/>*/}
								</div>

								{
									this.state.searchData && this.state.searchData.length > 0 &&
									<div className="form-group col">
										<label>Veldu flettu til samanburðar:</label>
										<div className="card" style={{maxHeight: '128px', overflowY: 'auto'}}>
										{
											this.state.searchData.map((item) => {
												return <a className={'nav-link pt-1 pb-1'}
												onClick={() => {
													this.setState({
														tengifletta: item
													}, () => {
														this.fetchData(this.props.fldata.flid, this.state.tengifletta.flid);
													});
												}}
												>{item.fletta}<span dangerouslySetInnerHTML={{__html: (item.greining ? '<small class="color-blue">&nbsp;<em>'+item.greining+'</em></small>' : '')}} /></a>;
											})
										}
										</div>
									</div>
								}

								<div className="form-group col">

									<label htmlFor="tengingarTypeSelect">Tegund vensla:</label>

									<select className="form-control col-5" id="tengingarTypeSelect" size="5" value={this.state.selectedMethod} height="5" onChange={(event) => {
										this.setState({
											selectedMethod: event.target.value
										}, () => {
											if (this.state.tengifletta) {
												this.fetchData(this.props.fldata.flid, this.state.tengifletta.flid);
											}
										});
									}}>
										{
											function() {
												let ret = [];
												for (let key in this.relationTypes) {
													ret.push(<option key={key} value={key}>{this.relationTypes[key]}</option>)
												}

												return ret;
											}.bind(this)()
										}
									</select>
								</div>

							</div>
							{
								this.state.searchData && this.state.searchData.length == 0 &&
								<p className="mt-2">Flettan <strong>{this.state.searchBoxInput}</strong> fannst ekki.</p>
							}

							{/*
							<button className="btn btn-primary" disabled={!this.state.tengifletta} onClick={() => {
								if (this.state.tengifletta) {
									this.fetchData(this.props.fldata.flid, this.state.tengifletta.flid);
								}
							}}>Leita að venslum</button>
							*/}

							{
								this.state.tengifletta &&
								<div className="form-group">
									<hr/>
									<label className="mt-2" htmlFor="tengingarTypeSelect">Valdar flettur:</label>
									<div className="d-flex mb-4">

									<div className="tengifletta">
									{this.props.fldata.fletta}{/* <small className="color-orange">{this.props.fldata.ofl}</small>*/}
									{
										this.props.fldata.greining &&
										<small className="color-blue">&nbsp;<em>{this.props.fldata.greining}</em></small>
									}
									</div>

									<div className="tenging-strik">
										<span>{this.relationTypes[this.state.selectedMethod]}</span>
									</div>

									<TengiflettuLeit tengifletta={this.state.tengifletta} />

									</div>
								</div>
							}

						</div>
					</div>


					<GraphView normalStrenght={true}
						straightLines={true}
						collideRadius={40}
						disableZoom={false}
						enableFullView={true}
						distance={50}
						disableArrows={true}
						graphData={this.state.graphData}
						waiting={this.state.waiting}
						onNodeClick={function(d) {
							console.log(d)
							this.props.history.push('/fletta/'+d.properties.flid.low);
						}.bind(this)}
						highlightFlid={[this.props.fldata.flid, this.state.tengifletta ? this.state.tengifletta.flid : -1]} />

				</Tab>
			}
		</React.Fragment>;
	}
}


export default withRouter(TengingarView);
