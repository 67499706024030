import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';
import SkyldheitiGraphView from './SkyldheitiGraphView';
import SkyldheitiFelagarGraphView from './SkyldheitiFelagarGraphView';

import config from './../config';

function SkyldsamView(props) {
	const [data, setData] = useState({ results: []});

	useEffect(() => {
		if (props.frumflid && props.skyldflid) {
			fetch(config.apiRoot+'/api/skyldheiti/?frumflid='+props.frumflid+'&skyldflid='+props.skyldflid)
				.then((result) => result.json())
				.then((json) => {
					setData(json);
				});
		}

	}, [props.frumflid, props.skyldflid]);

	return <React.Fragment>
		{
			data && data.results.length > 0 &&
			<div className="card gray-card">
				{
					props.subItemVisible &&
					<SkyldheitiFelagarGraphView flid={props.frumflid} skyldflid={props.skyldflid} graphHeight="350" />
				}

				<div className="card-body">{'Sameiginlegir félagar: '}<br/>
				{
					data.results.map((item, index) => {
						return <span key={index}>{index > 0 ? ', ' : ''}<Link to={'/fletta/'+item.tengiflid}>{item.tengifletta}</Link></span>;
					})
				}
				</div>
			</div>
		}
	</React.Fragment>;
}

function TableItemView(props) {
	const [subItemVisible, setSubItemVisible] = useState(false);

	return <React.Fragment>
		<tr>
			<td>{props.item.sameiginlegir_felagar} <small>( af {Math.round(props.item.sameiginlegir_felagar*(1000/(props.item.hlutfall)))})</small></td>
			<td>{(props.item.hlutfall/10)+'%'}</td>
			<td><button className="btn btn-secondary" onClick={() => {
				setSubItemVisible(!subItemVisible);
			}}>+</button></td>
			<td><Link className={subItemVisible ? 'font-weight-bold' : ''} to={'/fletta/'+props.item.skyldflid}>{props.item.skyldfletta}</Link></td>
		</tr>
		<tr>
			<td style={subItemVisible ? {} : {display: 'none'}} colSpan="4" className="text-center">
				<SkyldsamView frumflid={props.flid} skyldflid={props.item.skyldflid} subItemVisible={subItemVisible} />
			</td>
		</tr>
	</React.Fragment>;
}

function SkyldheitiView(props) {
	const [data, setData] = useState([]);
	const [order, setOrder] = useState(null);

	useEffect(() => {
		if (props.flid && props.selectedTab == props.dataType && data.length == 0) {
			fetch(config.apiRoot+'/api/skyldsam/?flid='+props.flid)
				.then((result) => result.json())
				.then((json) => {
					setData(json.results);
				});
		}

	}, [props.selectedTab, order]);

	useEffect(() => {
		let sorted = (order == 'sameiginlegir_felagar' ? _.sortBy(data, (item) => {
			return item.sameiginlegir_felagar;
		}).reverse() : order == 'skyldfletta' ? _.sortBy(data, (item) => {
			return item.skyldfletta;
		}) : order == 'hlutfall' ? _.sortBy(data, (item) => {
			return item.hlutfall;
		}).reverse() : data);

		setData(sorted)
	}, [order]);

	let headers = [
		{
			label: 'Sameiginlegir félagar (tengiflettur)',
			field: 'sameiginlegir_felagar',
			sortable: true
		},
		{
			label: 'Hlutfall',
			field: 'hlutfall',
			sortable: true
		},
		{
			label: 'Myndrit',
			sortable: false
		},
		{
			label: 'Skyldheiti',
			field: 'skyldfletta',
			sortable: true,
			width: '50%'
		},
	]

	return <React.Fragment>
		{
			data && data.length > 0 &&
			<Tab visible={props.selectedTab == props.dataType}
				tabColor={props.tabColor}
				heading="Skyldheiti"
				description="Aðrar flettur sem flettan tengist í gegnum parasambönd með sameiginlegum félögum."
				helpContent={
					<>
						<Link to="/s/hjalp#skyldheiti">Um skyldheiti</Link>
					</>
				}>

				<SkyldheitiGraphView flid={props.flid} />

				<table className="mt-4">
					<thead>
						<tr>
							{
								headers.map((item) => {
									return <th width={item.width || null}>
										{
											item.sortable && <a onClick={() => {
												setOrder(item.field);
											}}>
												{
													item.field == order &&
													<span className="color-orange">{item.label}</span>
												}
												{
													item.field != order && item.label
												}
											</a>
										}
										{
											!item.sortable && item.label
										}
									</th>
								})
							}
						</tr>
					</thead>
					<tbody>
					{
						data.map((item, index) => {
							return <TableItemView flid={props.flid} key={index} item={item} />
						})
					}
					</tbody>
				</table>
			</Tab>
		}
	</React.Fragment>
}


export default withRouter(SkyldheitiView);
