import React, { Component } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";

import config from './../config';

class PageMenu extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/vefur/api/pages/';

		this.state = {
			listData: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.site && prevProps.site != this.props.site) {
			this.fetchData();
		}
	}

	fetchData() {
		// Sæki gögn til APA
		this.setState({
			listData: []
		});

		fetch(this.url+'?site='+(this.props.site || 1))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results
				});
			}.bind(this));
	}

	render() {

		return (
			<React.Fragment>
				<div className="menu-links hidden-mobile">
					{
						this.state.listData.length > 0 &&
						this.state.listData.map(function(item, index) {
							return <span key={index} className={item.menu_separator ? ' separator' : ''}>
								<NavLink key={index} exact to={item.url}>{item.title}</NavLink>
							</span>;
						}.bind(this))
					}

				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(PageMenu);
