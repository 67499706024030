import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';
import GraphView from './GraphView';

import config from './../config';
import utils from './../utils';

class SkyldheitiFelagarGraphView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			graphData: {}
		};
	}

	componentDidMount() {
		if (this.props.flid && this.props.skyldflid) {
			this.getData(this.props.flid, this.props.skyldflid);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.props)
		if (this.props.flid && this.props.skyldflid &&
			((this.props.flid != prevProps.flid) || (this.props.skyldflid != prevProps.skyldflid))
		) {
			this.getData(this.props.flid, this.props.skyldflid)
		}
	}

	getData(flid, skyldflid) {
		fetch(config.neo4jApi+'/skyldheiti/felagar/?flid='+flid+'&skyldflid='+skyldflid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json)
				});
			});
	}

	render() {
		return <GraphView normalStrenght={true}
			straightLines={true}
			collideRadius={30}
			disableZoom={true}
			distance={100}
			graphData={this.state.graphData}
			onNodeClick={function(d) {
				this.props.history.push('/fletta/skyldheiti/'+d.properties.flid.low);
			}.bind(this)}
			graphHeight={this.props.graphHeight}
			highlightFlid={[this.props.flid, this.props.skyldflid]} />;
	}
}


export default withRouter(SkyldheitiFelagarGraphView);
