import React, { Component } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';

import config from './../config';

class SamsetningarView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fyrriLidurData: [],
			seinniLidurData: [],
			samsettUrData: []
		};
	}

	componentDidMount(prevProps, prevState) {
		if (this.props.flid) {
			this.getData(this.props.flid)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.flid &&
			this.props.selectedTab == this.props.dataType &&
			(this.state.fyrriLidurData.length == 0 && this.state.seinniLidurData.length == 0 && this.state.samsettUrData.length == 0)
		) {
			this.getData(this.props.flid)
		}
	}

	getData(flid) {
		// Fyrri liður
		if (this.state.fyrriLidurData.length == 0) {
			fetch(config.apiRoot+'/api/samsetningar/?flid1='+flid)
				.then((result) => result.json())
				.then((json) => {
					this.setState({
						fyrriLidurData: json.results
					});;
				});
		}

		// Seinni liður
		if (this.state.seinniLidurData.length == 0) {
			fetch(config.apiRoot+'/api/samsetningar/?flid2='+flid)
				.then((result) => result.json())
				.then((json) => {
					this.setState({
						seinniLidurData: json.results
					});;
				});
		}

		// Samsett úr
		if (this.state.samsettUrData.length == 0) {
			fetch(config.apiRoot+'/api/samsetningar/?flid3='+flid)
				.then((result) => result.json())
				.then((json) => {
					this.setState({
						samsettUrData: json.results
					});;
				});
		}

	}

	render() {
		return <React.Fragment>
			{
				<Tab visible={this.props.selectedTab == this.props.dataType}
					tabColor={this.props.tabColor}
					heading="Samsetningar"
					description="Samsett orð sem flettan kemur fram í og/eða orðhlutar sem mynda flettuna."
					helpContent={
						<>
							<Link to="/s/hjalp#samsetningar">Um samsetningar</Link>
						</>
					}>

					<div className="row">

						{
							this.state.fyrriLidurData.length > 0 &&
							<div className="col">
								<strong>Fyrri liður samsetningar</strong>
								{
									this.state.fyrriLidurData.map(function(item, index) {
										let textiFrags = item.texti.split('-');

										return <div key={index}>{textiFrags[0]}-<Link to={'/fletta/'+item.flid2}>{textiFrags[1]}</Link> → <Link to={'/fletta/'+item.flid3}>{item.fletta3}</Link></div>;
									})
								}
							</div>
						}

						{
							this.state.seinniLidurData.length > 0 &&
							<div className="col">
								<strong>Seinni liður samsetningar</strong>
								{
									this.state.seinniLidurData.map(function(item, index) {
										let textiFrags = item.texti.split('-');

										if (item.ofl == 'forl') {
											return <div key={index}>{textiFrags[0]}-{textiFrags[1]} → <Link to={'/fletta/'+item.flid3}>{item.fletta3}</Link></div>;
										}
										else {
											return <div key={index}><Link to={'/fletta/'+item.flid1}>{textiFrags[0]}</Link>-{textiFrags[1]} → <Link to={'/fletta/'+item.flid3}>{item.fletta3}</Link></div>;
										}
									})
								}
							</div>
						}

						{
							this.state.samsettUrData.length > 0 &&
							<div className="col">
								<strong>Samsett úr</strong>
								{
									this.state.samsettUrData.map(function(item, index) {
										let textiFrags = item.texti.split('-');

										return <div key={index}><Link to={'/fletta/'+item.flid1}>{textiFrags[0]}</Link>-<Link to={'/fletta/'+item.flid2}>{textiFrags[1]}</Link> → <Link to={'/fletta/'+item.flid3}>{item.fletta3}</Link></div>;
									}.bind(this))
								}
							</div>
						}

					</div>
				</Tab>
			}
		</React.Fragment>;
	}
}


export default SamsetningarView;
