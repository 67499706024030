import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';
import PieChartView from './PieChartView';
import * as d3 from 'd3';

import config from './../config';

function FlettaDagsins(props) {
	const [data, setData] = useState(null);

	useEffect(() => {
		if (!data) {
			fetch(config.apiRoot+'/api/fletta_dagsins'
				)
				.then((result) => result.json())
				.then((json) => {
					if (json.results && json.results[0]) {
						setData(json.results[0]);
					}
				});
		}

	});

	return <React.Fragment>
		{
			data &&
			<React.Fragment>
				<h4>Fletta dagsins</h4>
				<p><Link to={'/fletta/'+data.fletta.split(' ').join('_')}>{data.fletta}</Link></p>
			</React.Fragment>
		}
	</React.Fragment>;
}


export default FlettaDagsins;
