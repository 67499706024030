import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';

import config from './../config';

class MetinVenslView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			samheitiData: [],
			andheitiData: [],
			stiklaData: []
		};
	}

	componentDidMount(prevProps, prevState) {
		if (this.props.flid &&
			this.props.selectedTab == this.props.dataType &&
			(this.state.samheitiData.length == 0 && this.state.andheitiData.length == 0 && this.state.stiklaData.length == 0)
		) {
			this.getVenslData(this.props.flid)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.flid &&
			this.props.selectedTab == this.props.dataType &&
			(this.state.samheitiData.length == 0 && this.state.andheitiData.length == 0 && this.state.stiklaData.length == 0)
		) {
			this.getVenslData(this.props.flid)
		}
	}

	getVenslData(flid) {
		// Samheiti
		fetch(config.apiRoot+'/api/samheiti/?flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					samheitiData: json.results
				});;
			});

		// Andheiti
		fetch(config.apiRoot+'/api/andheiti/?flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					andheitiData: json.results
				});;
			});

		// Stikla
		fetch(config.apiRoot+'/api/stikla/?flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					stiklaData: json.results
				});;
			});

	}

	render() {
		return <React.Fragment>
			{
				<Tab visible={this.props.selectedTab == this.props.dataType}
					tabColor={this.props.tabColor}
					heading="Metin vensl"
					description="Vensl samkvæmt huglægu mati, án hlutlægrar greiningar á efni Orðanetsins."
					extraContent={
						this.props.talningData && this.props.talningData.fjgrann > 0 ?
						<>Sjá einnig <Link to={this.props.history.location.pathname.replace('/fletta/metin_vensl/', '/fletta/grannheiti/')}>grannheiti</Link></> : null
					}
					helpContent={
						<>
							<Link to="/s/hjalp#samheiti">Um samheiti</Link>
							<Link to="/s/hjalp#andheiti">Um andheiti</Link>
							<Link to="/s/hjalp#stiklur">Um stiklur</Link>
						</>
					}>

					<div className="row">

						{
							this.state.samheitiData.length > 0 &&
							<div className="col">
								<strong>Samheiti</strong>
								{
									this.state.samheitiData.map(function(item, index) {
										return <div key={index}><Link to={'/fletta/'+item.flid2}>{item.fletta2}</Link></div>;
									})
								}
							</div>
						}

						{
							this.state.andheitiData.length > 0 &&
							<div className="col">
								<strong>Andheiti</strong>
								{
									this.state.andheitiData.map(function(item, index) {
										return <div key={index}><Link to={'/fletta/'+item.flid2}>{item.fletta2}</Link></div>;
									})
								}
							</div>
						}

						{
							this.state.stiklaData.length > 0 &&
							<div className="col">
								<strong>Stikla</strong>
								{
									this.state.stiklaData.map(function(item, index) {
										return <div key={index}><Link to={'/fletta/'+item.flid2}>{item.fletta2}</Link></div>;
									})
								}
							</div>
						}

					</div>
				</Tab>
			}
		</React.Fragment>;
	}
}


export default withRouter(MetinVenslView);
