import React, { Component } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';

import config from './../config';
import utils from './../utils';

class HugtokView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		if (this.props.flid && this.props.selectedTab == this.props.dataType && this.state.data.length == 0) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.flid && this.props.selectedTab == this.props.dataType && this.state.data.length == 0) {
			this.fetchData();
		}
	}

	fetchData() {
		fetch(config.apiRoot+'/api/hugtok/?flid='+this.props.flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					data: json.results
				});

				if (json.results[0]) {
					this.getHugtakData(json.results[0].hugtaksflid)
				}
			});
	}

	getHugtakData(flid) {
		this.setState({
			selectedHugtak: flid
		});

		// Flettur undir hugtakinu
		fetch(config.apiRoot+'/api/flettur_undir_hugtaki/?limit=1000&flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					fletturUndirHugtaki: json.results
				});
			});

		// Venslaðar flettur í gegnum pör
		fetch(config.apiRoot+'/api/flettur_undir_porum/?limit=1000&flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					fletturUndirPorum: json.results
				});
			});

		// Tengd hugtök
		fetch(config.apiRoot+'/api/tengd_hugtok/?limit=1000&flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					tengdHugtok: json.results,
					tengdHugtokRodun: 'fjoldi',
					fletturUndirHugtakiFilter: 'all',
					fletturUndirPorumFilter: 'all'
				});
			});

	}

	render() {
		let fletturUndirHugtakiStaftala;
		let fletturUndirHugtaki;
		if (this.state.fletturUndirHugtaki) {
			fletturUndirHugtakiStaftala = _.compact(_.uniq(_.map(this.state.fletturUndirHugtaki, (item) => item.felagi1staftala))).sort();

			if (this.state.fletturUndirHugtakiFilter && this.state.fletturUndirHugtakiFilter != 'all') {
				fletturUndirHugtaki = _.filter(this.state.fletturUndirHugtaki, (item) => {
					return item.felagi1staftala == this.state.fletturUndirHugtakiFilter;
				});
			}
			else {
				fletturUndirHugtaki = this.state.fletturUndirHugtaki;
			}
		}

		let fletturUndirPorumStaftala = [];
		let fletturUndirPorum;
		if (this.state.fletturUndirPorum) {
			fletturUndirPorumStaftala = _.compact(_.uniq(_.map(this.state.fletturUndirPorum, (item) => item.parvenslastaftala))).sort();

			if (this.state.fletturUndirPorumFilter && this.state.fletturUndirPorumFilter != 'all') {
				fletturUndirPorum = _.filter(this.state.fletturUndirPorum, (item) => {
					return item.parvenslastaftala == this.state.fletturUndirPorumFilter;
				});
			}
			else {
				fletturUndirPorum = this.state.fletturUndirPorum;
			}
		}

		let tengdHugtok = this.state.tengdHugtok || [];
		if (this.state.tengdHugtok && this.state.tengdHugtokRodun) {
			tengdHugtok = _.sortBy(this.state.tengdHugtok, this.state.tengdHugtokRodun);

			if (this.state.tengdHugtokRodun == 'fjoldi') {
				tengdHugtok = tengdHugtok.reverse();
			}
		}

		return <React.Fragment>
			{
				this.state.data && this.state.data.length > 0 &&
				<Tab visible={this.props.selectedTab == this.props.dataType}
					tabColor={this.props.tabColor}
					heading="Hugtök"
					description="Sameinandi heiti merkingarlega samstæðra flettna, óháð orðflokki."
					helpContent={
						<>
							<Link to="/s/hjalp#hugtok">Um hugtök</Link>
						</>
					}>

					<ul className="nav nav-pills">
						{
							this.state.data.map((item, index) => {
								return <a className={'nav-link btn mr-2 btn-secondary'+(this.state.selectedHugtak == item.hugtaksflid ? ' active' : '')} key={index} onClick={function() {
									this.getHugtakData(item.hugtaksflid);
								}.bind(this)}>{item.texti}</a>;
							})
						}
					</ul>

					<div className="row">

						<div className="col-sm-4">
							{
								this.state.fletturUndirHugtaki && fletturUndirHugtaki &&
								<div>
									<strong>Flettur undir hugtakinu</strong>

									<div className="form-inline order-form">
										<label className="mr-2">Sía lista: </label>
										<select value={this.state.fletturUndirHugtakiFilter} type="select" className="form-control" onChange={(event) => {
											this.setState({
												fletturUndirHugtakiFilter: event.target.value
											})
										}}>
											<option key="0" value="all">Óháð orðflokkum</option>
											{
												fletturUndirHugtakiStaftala.map((item, index) => <option key={index+1}
														value={item}
													>
														{utils.getStaftalaLabel(item)} ({_.where(this.state.fletturUndirHugtaki, {felagi1staftala: item}).length})
													</option>
												)
											}
										</select>
									</div>

									{
										fletturUndirHugtaki.map(function(item, index) {
											return <div key={index}><Link to={'/fletta/'+item.felagi1flid}>{item.felagi1fletta}</Link></div>;
										})
									}
								</div>
							}
						</div>

						<div className="col-sm-4">
							{
								this.state.fletturUndirPorum && fletturUndirPorum &&
								<div>
									<strong>Venslaðar flettur í gegnum pör</strong>

									<div className="form-inline order-form">
										<label className="mr-2">Sía lista: </label>
										<select value={this.state.fletturUndirPorumFilter} type="select" className="form-control" onChange={(event) => {
											this.setState({
												fletturUndirPorumFilter: event.target.value
											})
										}}>
											<option key="0" value="all">Óháð orðflokkum</option>
											{
												fletturUndirPorumStaftala.map((item, index) => <option key={index+1}
														value={item}
													>
														{utils.getStaftalaLabel(item)} ({_.where(this.state.fletturUndirPorum, {parvenslastaftala: item}).length})
													</option>
												)
											}
										</select>
									</div>

									{
										fletturUndirPorum.map(function(item, index) {
											return <div key={index}><Link to={'/fletta/'+item.parvenslaflid}>{item.parvenslafletta}</Link></div>;
										})
									}
								</div>
							}
						</div>

						<div className="col-sm-4">
							{
								this.state.tengdHugtok &&
								<div>
									<strong>Tengd hugtök</strong>

									<div className="form-inline order-form">
										<label className="mr-2">Röðun lista: </label>
										<select value={this.state.tengdHugtokRodun} type="select" className="form-control" onChange={(event) => {
											this.setState({
												tengdHugtokRodun: event.target.value
											})
										}}>
											<option value="fjoldi">Raðað eftir vægi</option>
											<option value="hugtaksfletta2">Stafrófsröð</option>
										</select>
									</div>

									{
										tengdHugtok.map((item, index) => {
											return <div key={index}><Link to={'/fletta/'+item.hugtaksflid2}><span className="text-uppercase">{item.hugtaksfletta2}</span>{this.state.tengdHugtokRodun && this.state.tengdHugtokRodun == 'fjoldi' && <small className="color-orange"> {item.fjoldi}</small>}</Link></div>;
										})
									}
								</div>
							}
						</div>

					</div>
				</Tab>
			}
		</React.Fragment>;
	}
}


export default HugtokView;
