import React, { Component } from 'react';
import * as d3 from 'd3';

class PieChartView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hoverInfo: null
		}
	}

	componentDidMount() {
		window.d3 = d3;
		this.createGraph();

		if (this.props.data) {
			this.drawGraph();
		}
	}

	createGraph() {
		let color = d3.scaleOrdinal().range(d3.schemePastel1);

		let width = 300;
		let height = 300;
		let radius = Math.min(width, height) / 2;

		let arc = d3.arc()
			.outerRadius(radius - 10)
			.innerRadius(0);

		let labelArc = d3.arc()
			.outerRadius(radius - 40)
			.innerRadius(radius - 40);

		let pie = d3.pie()
			.sort(null)
			.value(function(d) {
				return d.value;
			});

		let svg = d3.select(this.refs.graphContainer).append('svg')
			.attr('width', width)
			.attr('height', height)
			.append('g')
			.attr('transform', 'translate('+width/2+','+height/2+')');

		let g = svg.selectAll('.arc')
			.data(pie(this.props.data))
			.enter().append('g')
			.attr('class', 'arc');

		g.append('path')
			.attr('d', arc)
			.style('fill', function(d) {
				return color(d.data.value);
			})
			.on('mouseover', function(d) {
				this.setState({
					hoverInfo: d.data.label+' ('+d.data.value+')'
				});
			}.bind(this))
			.on('mouseout', function(d) {
				this.setState({
					hoverInfo: null
				});
			}.bind(this));

		g.append('text')
			.attr('transform', function(d) { return 'translate(' + labelArc.centroid(d) + ')'; })
			.attr('dy', '.35em')
			.text(function(d) {
				return d.data.value;
			})
			.on('mouseover', function(d) {
				this.setState({
					hoverInfo: d.data.label+' ('+d.data.value+')'
				});
			}.bind(this))
			.on('mouseout', function(d) {
				this.setState({
					hoverInfo: null
				});
			}.bind(this));

	}

	drawGraph() {

	}

	render() {
		let colors = d3.scaleOrdinal().range(d3.schemePastel1);

		return <div className="piechart">
			<div ref="graphContainer" />
			{
				this.state.hoverInfo &&
				<div className="hover-info" dangerouslySetInnerHTML={{__html: this.state.hoverInfo}} />
			}

			{/*<div className="graph-legend">
				{
					this.props.data.map((item, index) => {
						console.log(colors(index))
						return <div className="legend-item">
							<div className="legend-color" style={{
								backgroundColor: colors(index)
							}} />
							<span className="legend-label">{item.label} ({item.value})</span>
						</div>
					})
				}
			</div>*/}
		</div>;
	}
}

export default PieChartView;
