import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';

import config from './../config';

function OsambView(props) {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (props.flid && props.selectedTab == props.dataType && data.length == 0) {
			fetch(config.apiRoot+'/api/osamb/?flid='+props.flid)
				.then((result) => result.json())
				.then((json) => {
					setData(json.results);
				});
		}

	}, [props.selectedTab]);

	return <React.Fragment>
		{
			data && data.length > 0 &&
			<Tab visible={props.selectedTab == props.dataType}
				tabColor={props.tabColor}
				heading="Orðastæður"
				description="Laustengt orðasamband sem birtir einkennandi notkun flettunnar."
				helpContent={
					<>
						<Link to="/s/hjalp#ordastaedur">Um orðastæður</Link>
					</>
				} >
				<p>Orðastæður eru sjálfstæðar einingar í Orðanetinu. Þær hafa ekki stöðu fleiryrtrar flettu og birtast því ekki sem krækjur.</p>
				<table>
					<thead>
						<tr>
							<th>Orðastæða</th>
							<th>Aðrar tengdar flettur</th>
						</tr>
					</thead>
					<tbody>
					{
						data.map((item, index) => {
							return <tr key={index}>
								<td>{item.texti}</td>
								<td>
									{
										item.adrar && item.adrar.map((aItem, aIndex) => {
											return <React.Fragment key={aIndex}>
												{
													aIndex > 0 &&
													<React.Fragment> / </React.Fragment>
												}
												<Link to={'/fletta/'+aItem.flid}>{aItem.fletta}</Link>
											</React.Fragment>
										})
									}
								</td>
							</tr>;
						})
					}
					</tbody>
				</table>
			</Tab>
		}
	</React.Fragment>;
}


export default OsambView;
