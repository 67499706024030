import * as d3 from 'd3';
import _ from 'underscore';

export default {
	linkTypes: {
		'GRANNHEITI': 'Grannheiti',
		'SKYLDHEITI': 'Skyldheiti',
		'HUGTAK': 'Hugtak',
		'SAMHEITI': 'Samheiti',
		'ANDHEITI': 'Andheiti',
		'UNDIR_HUGTAKI': 'Hugtak',
		'SAM_FELAGI': 'Sameiginlegur félagi skyldheita',
		'GRANNFL_TENGIFL': 'Tengifletta grannheita',
		'PAR': 'Par',
		'STIKLA': 'Stikla'
	},

	linkColors: {
		'GRANNHEITI': d3.schemeCategory10[0],
		'SKYLDHEITI': d3.schemeCategory10[1],
		'HUGTAK': d3.schemeCategory10[2],
		'SAMHEITI': d3.schemeCategory10[3],
		'ANDHEITI': d3.schemeCategory10[4],
		'UNDIR_HUGTAKI': d3.schemeCategory10[5],
		'SAM_FELAGI': d3.schemeCategory10[6],
		'GRANNFL_TENGIFL': d3.schemeCategory10[7],
		'STIKLA': d3.schemeCategory10[8]
	},

	staftalaLabels: {
		'1': 'Nafnorð og nafnliðir',
		'2': 'Lýsingarorð og lo.liðir',
		'3': 'Sagnorð og sagnasambönd',
		'4': 'Atviksorð og atviksliðir',
		'7': 'Leitarorð í breytilið',
		'9': 'Annað'
	},

	getStaftalaLabel: function(staftala) {
		return this.staftalaLabels[staftala] || staftala;
	},

	groupGraphData: function(graphData) {
		return graphData;

		let connectionTypes = _.uniq(_.map(graphData.connections, function(item) {
			return item.properties.type;
		}));

		let connections = [];

		let mainNodeFlid = _.find(graphData.nodes, function(item) {
			return item.properties.flid.low == this.props.flid;
		}.bind(this)).properties.flid.low;

		_.each(connectionTypes, function(type) {
			graphData.nodes.push({
				id: type,
				label: type,
				properties: {
					ofl: 'group',
					fletta: type
				}
			});

			_.each(graphData.connections, function(connection) {
				if (connection.properties.type == type) {
					let clone = _.clone(connection)
					clone.target = type;
					clone.source = connection.source

					console.log(clone)
					connections.push(clone);
				}
			});
		});

		graphData.connections = connections;

		return graphData;
	},

	displayOfl: function(ofl) {
		return ['so', 'lo', 'no', 'no kvk', 'no kk', 'no hvk'].indexOf(ofl) > -1;
	}
}
