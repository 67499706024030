import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';
import GraphView from './GraphView';

import config from './../config';
import utils from './../utils';

class GrannheitiGraphView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			graphData: {},
			graphMode: 1
		};
	}

	componentDidMount() {
		if (this.props.flid) {
			this.getData(this.props.flid);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.flid && this.props.flid != prevProps.flid) {
			this.getData(this.props.flid)
		}
	}

	getData(flid) {
		let params = [];

		if (this.state.graphMode == 2) {
			params.push('tengiflettur=true');
		}
		if (this.state.graphMode == 3) {
			params.push('extended=true');
		}

		fetch(config.neo4jApi+'/grannheiti/?flid='+flid+(params.length > 0 ? '&'+params.join('&') : ''))
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json)
				});
			});
	}

	render() {
		return <div>
			<GraphView straightLines={true}
				distance={50}
				graphData={this.state.graphData}
				highlightFlid={[this.props.flid]}
				onNodeClick={function(d) {
					this.props.history.push('/fletta/grannheiti/'+d.properties.flid.low);
				}.bind(this)}
				enableFullView={true}>

				<ul className="nav nav-pills mb-3">
					<a className={'nav-link btn mr-2 btn-secondary'+(this.state.graphMode == 1 ? ' active' : '')} onClick={function() {
						this.setState({
							graphMode: 1
						}, function() {
							this.getData(this.props.flid);
						}.bind(this));
					}.bind(this)}>Grannheiti</a>
					<a className={'nav-link btn mr-2 btn-secondary'+(this.state.graphMode == 2 ? ' active' : '')} onClick={function() {
						this.setState({
							graphMode: 2
						}, function() {
							this.getData(this.props.flid);
						}.bind(this));
					}.bind(this)}>Grannheiti ásamt tengiflettum</a>

					<a className={'nav-link btn mr-2 btn-secondary'+(this.state.graphMode == 3 ? ' active' : '')} onClick={function() {
						this.setState({
							graphMode: 3
						}, function() {
							this.getData(this.props.flid);
						}.bind(this));
					}.bind(this)}>Grannheiti ásamt grannheitum sínum</a>

				</ul>

			</GraphView>
		</div>
	}
}


export default withRouter(GrannheitiGraphView);
