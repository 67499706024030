import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';
import GraphView from './GraphView';

import config from './../config';
import utils from './../utils';

class YfirlitGraphView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			graphData: {}
		};
	}

	componentDidMount() {
		if (this.props.flid) {
			this.getData(this.props.flid);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.flid && this.props.flid != prevProps.flid) {
			this.getData(this.props.flid)
		}
	}

	getData(flid) {
		// Samheiti
		fetch(config.neo4jApi+'/yfirlit/?flid='+flid)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json)
				});
			});
	}

	render() {
		return <GraphView graphData={this.state.graphData}
			collideRadius={20}
			disableArrows={true}
			highlightFlid={[this.props.flid]}
			capitalizeHugtak={true}
			onNodeClick={function(d) {
				this.props.history.push('/fletta/'+d.properties.flid.low);
			}.bind(this)}
			enableFullView={true} />;
	}
}


export default withRouter(YfirlitGraphView);
