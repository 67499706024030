
import React, { useState, useEffect } from 'react';
import { NavLink, useRouteMatch, useParams, useHistory } from "react-router-dom";
import _ from 'underscore';

import config from './../config';

function ListSection(props) {
	let history = useHistory();
	let [isOpen, setOpen] = useState(false);

	return <React.Fragment>
		<div className="list-heading">{props.heading}</div>
		{
			(props.data.length > 10 && !isOpen ? props.data.slice(0, 10) : props.data).map((item, index) => <li className="nav-item" key={index}>
				<NavLink className={'nav-link pt-1 pb-1'}
					to={'/fletta/yfirlit/'+item.querystring+item.urlauki}
				>{item.fletta} {item.rnum > 0 && <small>{item.rnum}</small>} {
					item.hugtaksflid && <button style={{marginTop: -2}}
						className="btn btn-secondary btn-sm float-right pt-0 pb-0"
						onClick={(event) => {
							event.preventDefault();

							history.push('/fletta/hugtok/'+item.querystring+item.urlauki);
						}}
					>HUGTAK</button>
				}</NavLink>
			</li>)
		}
		{
			props.data.length > 20 && !isOpen &&
			<li className="nav-item">
				<a className="nav-link btn btn-secondary mb-3" onClick={() => setOpen(true)}><strong>Sjá allar flettur ({props.data.length > 500 ? '500 >' : props.data.length})</strong></a>
			</li>
		}
		{
			props.data.length > 20 && isOpen &&
			<li className="nav-item">
				<a className="nav-link btn btn-secondary mb-3" onClick={() => setOpen(false)}><strong>Loka</strong></a>
			</li>
		}
	</React.Fragment>
}

function SearchResultsList(props) {
	const [fletturResults, setFletturResults] = useState([]);
	const [found, setFound] = useState(true);
	let history = useHistory();
	let { leitarOrd, querystring, tab, urlauki } = useParams();

	const listLabels = {
		'1': 'Nafnorð',
		'2': 'Lýsingarorð',
		'3': 'Sagnorð',
		'4': 'Atviksorð',
		'7': 'Leitarorð í breytilið',
		'8': 'Tölurorð',
		'hornklofi': 'Nafnorð sem er auðkenni með lýsingarorði',
		'oddklofi': 'Leitarorð í breytilið'
	}

	let formatResults = function(results) {
		let oddklofi = [];
		let hornklofi = [];

		let others = [];

		_.each(_.uniq(results, (item) => item.flid), function(item) {
			if (item.oddklofi) {
				oddklofi.push(item);
			}
			else if (item.hornklofi) {
				hornklofi.push(item);
			}
			else {
				others.push(item);
			}
		});

		let ret = _.groupBy(others, 'staftala');

		ret.hornklofi = hornklofi;
		ret.oddklofi = oddklofi;

		return ret;
	}

	useEffect(() => {
		if (leitarOrd || (querystring && fletturResults.length == 0)) {
			setFound(true);

			window.scrollTo(0, 0);

			fetch(config.apiRoot+'/api/flettur_leit/'+((leitarOrd || '').toLowerCase() || (querystring && querystring.split('_').length == 1) ? '?leitarstrengur='+((leitarOrd || '').toLowerCase() || querystring) : querystring ? '?querystring='+querystring : '')+'&limit=1000')
				.then((result) => result.json())
				.then((json) => {
					if (json.results.length == 0) {
						setFound(false);
					}

					setFletturResults(formatResults(json.results));

					if (json.results.length > 0 && json.results[0].querystring != querystring && json.results[0].urlauki != urlauki) {
						history.replace('/fletta/'+(tab || 'yfirlit')+'/'+json.results[0].querystring+json.results[0].urlauki);
					}
				});
		}

	}, [leitarOrd, querystring]);

	const disallowedResultTypes = ['9'];

	return <div className="card gray mb-4 tight-card">

		<div className="card-body">

			<ul className="nav nav-pills flex-column">
				{
					fletturResults && !(fletturResults.data) && function() {
						let elements = [];

						for (let key in fletturResults) {
							if (fletturResults[key].length > 0 && disallowedResultTypes.indexOf(key) == -1) {
								elements.push(<ListSection key={key} heading={listLabels[key] || key} data={fletturResults[key]} />);
							}
						}

						return elements;
					}()
				}
				{
					(!found && leitarOrd) &&
					<li className="nav-item mt-2 mr-2 mb-2 ml-2 h6"><strong>{leitarOrd}</strong> fannst ekki í neinni flettu.</li>
				}
			</ul>

		</div>

	</div>;
}

export default SearchResultsList;
