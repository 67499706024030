import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function Forsida(props) {
	return <div className="content-container">
		<div className="box">
			<h2>Íslenskt orðanet</h2>
			<p>Íslenskt orðanet er umfangsmikið yfirlit í orðabókarbúningi um íslenskan orðaforða og innra samhengi hans, byggt á greiningu á merkingarvenslum íslenskra orða og orðasambanda. Gengið er út frá þeirri forsendu að lesa megi merkingarvensl orða út úr setningarlegum og orðmyndunarlegum venslum þeirra eins og þau birtast í orðasamböndum og samsetningum. Í upphafi lá til grundvallar safn orðasambanda og samsetninga með samræmdri framsetningu sem hefur að geyma rösklega 200 þúsund orðasambönd af ólíku tagi og um 100 þúsund samsetningar. Þetta safn sameinar gagnaefni Stóru orðabókarinnar um íslenska málnotkun (2005) og Orðasambandaskrá Stofnunar Árna Magnússonar í íslenskum fræðum (áður Orðabókar Háskólans).  Til viðbótar þessu efni hefur mjög verið  leitað fanga í stafrænum textasöfnum og málheildum,  einkum í safninu Tímarit.is og Markaðri íslenskri málheild. Allt þetta efni er tengt flettulista sem sameinar um 250 þúsund einyrtar og fleiryrtar flettur.</p>
			<p className="links"><Link to="/s/um-ordanetid">Um orðanetið</Link> <Link to="/stadtolur">Staðtölur</Link> <Link to="/s/um-verkefnid">Um verkefnið</Link></p>
		</div>
	</div>;
}

export default Forsida;
