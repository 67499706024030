import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';

import config from './../config';

function SetningargerdView(props) {
	const [data, setData] = useState({ results: []});

	useEffect(() => {
		if (props.mark && props.selectedTab == props.dataType && data.results.length == 0) {
			fetch(config.apiRoot+'/api/flettur/?mark='+props.mark
				)
				.then((result) => result.json())
				.then((json) => {
					setData(json);
				});
		}

	}, [props.selectedTab]);

	return <React.Fragment>
		{
			data && data.results.length > 0 &&
			<Tab visible={props.selectedTab == props.dataType}
				tabColor={props.tabColor}
				heading="Setningagerð"
				description="Málfræðilegt mark sem lýsir gerð fleiryrtrar flettu">

				<p><strong>Fleiryrtar flettur af sömu setningargerð</strong>{data.results.length > 0 ? ' '+data.results.length : ''}</p>
				<p><em dangerouslySetInnerHTML={{__html: props.ofl_texti}} /></p>

				<ul className="nav nav-pills flex-column">
					{
						data.results.map((item, index) => {
							console.log(item)
							return <div key={index}><Link to={'/fletta/'+item.flid}>{item.fletta}</Link></div>;
						})
					}
				</ul>
			</Tab>
		}
	</React.Fragment>;
}


export default SetningargerdView;
