export default {
	pageSize: 50,

	apiRoot: '/django',
	//apiRoot: 'http://ordanet.is/django',
	//apiRoot: 'http://130.208.108.18:8000',

	//neo4jApi: 'http://130.208.108.18:3010/ordanet-neo4j',
	neo4jApi: '/graph',
	//neo4jApi: 'http://ordanet.is/graph',
	siteTitle: 'Íslenskt orðanet',

	gaTrackingId: 'G-QFZS3D5GEJ'
}
