import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';
import GrannheitiGraphView from './GrannheitiGraphView';

import config from './../config';

function TableItemView(props) {
	const [subItemVisible, setSubItemVisible] = useState(false);

	return <React.Fragment>
		<tr>
			<td>{props.item.length}</td>
			<td><button className="btn btn-secondary" onClick={() => {
				setSubItemVisible(!subItemVisible);
			}}>+</button></td>
			<td><Link to={'/fletta/'+props.item[0].grannflid}>{props.item[0].grannfletta}</Link></td>
		</tr>
		<tr>
			<td style={subItemVisible ? {} : {display: 'none'}} colSpan="3">
				{
					props.item.map(function(item, index) {
						return <div key={index} className="card mb-2 gray-card">
							<div className="card-body">
								<div className="d-flex align-items-center mt-3 mb-3">
									<div className=" d-flex align-items-center justify-content-between">
										<Link to={'/fletta/'+item.tengiflid}>{item.tengifletta}</Link>
										<div className="ml-4 mr-4" style={{fontSize: '3rem'}}>{'{'}</div>
									</div>
									<div className="">
										<div className="mt-2 mb-2">{item.frumtexti} ({item.frumtteg}) - {item.frumfletta}</div>
										<div className="mt-2 mb-2">{item.granntexti} ({item.granntteg}) - {item.grannfletta}</div>
									</div>
								</div>
							</div>
						</div>
					})
				}
			</td>
		</tr>
	</React.Fragment>;
}

function GrannheitiView(props) {
	const [data, setData] = useState([]);
	const [order, setOrder] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (props.flid && props.selectedTab == props.dataType && data.length == 0) {
			fetch(config.apiRoot+'/api/grannheiti/?flid='+props.flid)
				.then((result) => result.json())
				.then((json) => {
					let pregrouped = _.groupBy(json.results, 'grannflid');

					let grouped = [];

					for (let key in pregrouped) {
						grouped.push(pregrouped[key]);
					}

					grouped = _.sortBy(grouped, function(i) {
						return i.length;
					}).reverse();

					setData(grouped);
				});
		}

	}, [props.selectedTab]);

	useEffect(() => {
		let sorted = (order == 'hlutfall' ? _.sortBy(data, (item) => {
			return item.length;
		}).reverse() : order == 'grannfletta' ? _.sortBy(data, (item) => {
			return item[0].grannfletta;
		}) : data);

		setData(sorted)
	}, [order]);

	let headers = [
		{
			label: 'Tilvik',
			field: 'hlutfall',
			sortable: true
		},
		{
			label: 'Sjá tilvik',
			sortable: false
		},
		{
			label: 'Grannheiti',
			field: 'grannfletta',
			sortable: true,
			width: '80%'
		}
	]

	return <React.Fragment>
		{
			data && data.length > 0 &&
			<Tab visible={props.selectedTab == props.dataType}
				tabColor={props.tabColor}
				heading="Grannheiti"
				description="Merkingarlega náskyldar flettur (samheiti) samkvæmt samanburði og greiningu á orðasamböndum."
				extraContent={
					props.talningData && props.talningData.fjsamhe > 0 ?
					<>Sjá einnig <Link to={history.location.pathname.replace('/fletta/grannheiti/', '/fletta/metin_vensl/')}>samheiti</Link> undir flipanum „metin vensl“.</> : null
				}
				helpContent={
					<>
						<Link to="/s/hjalp#grannheiti">Um grannheiti</Link>
					</>
				}>

				<GrannheitiGraphView flid={props.flid} />

				<table className="mt-4">
					<thead>
						<tr>
							{
								headers.map((item, index) => {
									return <th key={index} width={item.width || null}>
										{
											item.sortable && <a onClick={() => {
												setOrder(item.field);
											}}>
												{
													item.field == order &&
													<span className="color-orange">{item.label}</span>
												}
												{
													item.field != order && item.label
												}
											</a>
										}
										{
											!item.sortable && item.label
										}
									</th>
								})
							}
						</tr>
					</thead>
					<tbody>
					{
						data.map((item, index) => {
							return <TableItemView key={index} item={item} />
						})
					}
					</tbody>
				</table>
			</Tab>
		}
	</React.Fragment>;
}


export default GrannheitiView;
