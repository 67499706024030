import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import AutocompleteInput from './AutocompleteInput';
import _ from 'underscore';

import config from "./../config";

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.inputKeyPressHandler = this.inputKeyPressHandler.bind(this);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.formatAutocompleteListLink = this.formatAutocompleteListLink.bind(this);
		this.formatAutocompleteListLabel = this.formatAutocompleteListLabel.bind(this);
		this.searchBoxItemSelectHandler = this.searchBoxItemSelectHandler.bind(this);
		this.inputFocusHandler = this.inputFocusHandler.bind(this);

		this.state = {
			searchBoxInput: ''
		};
	}

	inputKeyPressHandler(event) {
		if (event.key == 'Enter') {
			this.executeSearch();
		}
	}

	inputChangeHandler(event) {
		var stateObj = {};

		stateObj[event.target.name] = event.target.value;

		this.setState(stateObj);
	}

	componentDidMount() {
		if (this.props.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}

		this.refs.searchInput.refs.inputField.focus();

		setTimeout(function() {
			this.inputFocusHandler();
		}.bind(this), 100);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.searchString != prevProps.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}
	}

	searchBoxItemSelectHandler(item) {
		this.props.history.push('/fletta/'+item.flid);
	}

	inputFocusHandler() {
		this.refs.searchInput.refs.inputField.select();
	}

	executeSearch() {
		this.props.history.push('/leit/'+this.state.searchBoxInput);
	}

	formatAutocompleteListLink(item) {
		return '/fletta/'+item.flid;
	}

	formatAutocompleteListLabel(item) {
		return item.fletta.escape()+' <span class="color-orange">'+item.ofl+'</span>'+(item.greining ? ' <span class="color-blue"><em>'+item.greining+'</em></span>' : '');
	}

	render() {
		return (
			<React.Fragment>

				<div className="form-group row">
					<div className="col-9 col-sm-10 col-md-11 d-flex">
						<AutocompleteInput className="flex-fill"
							disabled={true}
							inputClassName="form-control"
							ref="searchInput"
							responseDataField="results"
							searchUrl={config.apiRoot+'/api/sprettileit/?fletta=$s'}
							onChange={this.inputChangeHandler}
							inputName="searchBoxInput"
							value={this.state.searchBoxInput}
							type="text"
							onEnter={this.executeSearch}
							onItemSelect={this.searchBoxItemSelectHandler}
							placeholder="Leita …"
							minChars={2}
							valueField="lykill"
							listLabelFormatFunc={this.formatAutocompleteListLabel}
							selectedItemClass="active"
							onFocus={this.inputFocusHandler}
							disableAutoFill={false}
							headerText="Ýttu á enter til að leita eða orð úr lista."
							listLinkFormatFunc={this.formatAutocompleteListLink}
						/>
					</div>
					<div className="col-2 col-md-1">
						<button onClick={this.executeSearch.bind(this)} className="btn btn-primary">Leita</button>
					</div>

				</div>
				<div className="search-info">… að samstæðum orðum og orðasamböndum og tengja þau við hugtök og merkingarflokka.</div>

			</React.Fragment>
		);
	}
}

export default withRouter(SearchBox);
