import React from 'react';

function SiteFooter(props) {
	let year = (new Date()).getFullYear();
	return 	<div className="site-footer">
		<div className="footer-text">© Jón Hilmar Jónsson og <a href="https://arnastofnun.is">Stofnun Árna Magnússonar í íslenskum fræðum</a> 2006-{year}.</div>
	</div>;
}


export default SiteFooter;
