import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';

import config from './../config';

const levenshtein = require('fast-levenshtein');

function PorView(props) {
	const [data, setData] = useState({ results: []});

	useEffect(() => {
		if (props.flid && props.selectedTab == props.dataType && data.results.length == 0) {
			fetch(config.apiRoot+'/api/por/?flid='+props.flid
				)
				.then((result) => result.json())
				.then((json) => {
					setData(json);
				});
		}

	}, [props.selectedTab]);

	return <React.Fragment>
		{
			data && data.results.length > 0 &&
			<Tab visible={props.selectedTab == props.dataType}
				tabColor={props.tabColor}
				heading="Pör"
				description="Hliðskipað samband tveggja flettna (tengt með og), sótt í textasamhengi."
				helpContent={
					<>
						<Link to="/s/hjalp#por">Pör</Link>
					</>
				}>

				<table className="paraTable">

					<tbody>
					{
						data.results.map((item, index) => {
							let textParts = item.texti.split(/( og | eða | né )/);

							let d1 = levenshtein.get(textParts[0].replace(/ ?<.+> ?/, '').replace(/ ?\(.+\) ?/, '').replace(/ ?\[.+\] ?/, ''), item.fletta1);
							let d2 = levenshtein.get(textParts[2].replace(/ ?<.+> ?/, '').replace(/ ?\(.+\) ?/, '').replace(/ ?\[.+\] ?/, ''), item.fletta1);

							console.log(textParts)
							console.log('d1: '+d1)
							console.log('d2: '+d2)

							if (d1 < d2) {
								return <tr key={index}>
									<td width="30%"></td><td className="text-right">{textParts[0]} <small>{textParts[1]}</small></td><td><Link to={'/fletta/'+item.flid2}>{textParts[2]}</Link></td>
								</tr>;
							}
							else {
								return <tr key={index}>
									<td width="30%"></td><td className="text-right"></td><td><Link to={'/fletta/'+item.flid2}>{textParts[0]}</Link> <small>{textParts[1]}</small> {textParts[2]}</td>
								</tr>;
							}
						})
					}
					</tbody>

				</table>

			</Tab>
		}
	</React.Fragment>;
}


export default PorView;
