import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';
import Tab from './Tab';
import PieChartView from './PieChartView';
import * as d3 from 'd3';

import config from './../config';

function StadtolurView(props) {
	const [data, setData] = useState(null);

	useEffect(() => {
		if (!data) {
			fetch(config.apiRoot+'/api/stadtolur'
				)
				.then((result) => result.json())
				.then((json) => {
					if (json.results && json.results[0]) {
						setData(json.results[0]);
					}
				});
		}

	});

	let formatDate = function(d) {
		let dateFrags = d.split('-');

		let months = [
			'janúar',
			'febrúar',
			'mars',
			'apríl',
			'maí',
			'júní',
			'júlí',
			'ágúst',
			'september',
			'október',
			'nóvember',
			'desember'
		];

		return parseInt(dateFrags[2])+'. '+months[parseInt(dateFrags[1])-1]+' '+dateFrags[0];
	}


	const colors = d3.scaleOrdinal().range(d3.schemePastel1);

	return <React.Fragment>
		{
			data &&
			<div className="content-container">
				<div className="box">
					{
						props.summary &&
						<h4>Staðtölur</h4>
					}
					{
						!props.summary &&
						<h3>Staðtölur</h3>
					}

					<p>Yfirlit yfir fjölda flettna í orðanetinu.</p>

					{
						!props.summary &&
						<p><strong>Nýjasta flettan</strong> <Link to={'/fletta/'+data.nyjasta_flid}>{data.nyjasta_fletta}</Link> ({formatDate(data.nyjasta_fletta_dags)})</p>
					}

					<table className="table">
						<tbody>
							<tr><td><strong>Flettur</strong></td><td width="60%">{data.flettur}</td></tr>
							<tr><td>Einyrtar</td><td>{data.flettur-data.fleiryrtar}</td></tr>
							<tr><td>Fleiryrtar</td><td>{data.fleiryrtar}</td></tr>
						</tbody>
					</table>

					{
						props.summary &&
						<p className="links"><Link to="/stadtolur">Sjá nánar</Link></p>
					}
					{
						!props.summary &&
						<>

							<div className="piechart">
								<div className="graph-legend">
								{
									[
										'Nafnorð / nafnliðir',
										'Lýsingarorð / lo.liðir',
										'Sagnorð / sagnasambönd',
										'Atviksorð / atviksliðir'
									].map((item, index) => {
										return <div className="legend-item">
										<div className="legend-color" style={{
											backgroundColor: colors(index)
										}} />
										<span className="legend-label">{item}</span>
										</div>
									})
								}
								</div>
							</div>

							<div className="row">

								<div className="col">
									<h5><strong>Hugtök</strong> ({data.hugtok})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.hugtok_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.hugtok_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.hugtok_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.hugtok_atviksord
											}
										]
									} />
								</div>

							</div>

							<div className="row">

								<div className="col">
									<h5><strong>Fjöldi parasambanda</strong> ({data.por_brutto})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.por_nafnord_brutto
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.por_lysingarord_brutto
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.por_sagnord_brutto
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.por_atviksord_brutto
											}
										]
									} />
								</div>

								<div className="col">
									<h5><strong>Flettur í parasamböndum</strong> ({data.por})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.por_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.por_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.por_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.por_atviksord
											}
										]
									} />
								</div>

							</div>

							<div className="row">

								<div className="col">
									<h5><strong>Skyldheiti</strong> ({data.skyldheiti})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.skyldheiti_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.skyldheiti_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.skyldheiti_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.skyldheiti_atviksord
											}
										]
									} />
								</div>

								<div className="col">
									<h5><strong>Grannheiti</strong> ({data.grannheiti})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.grannheiti_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.grannheiti_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.grannheiti_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.grannheiti_atviksord
											}
										]
									} />
								</div>

							</div>

							<div className="row">

								<div className="col">
									<h5><strong>Samheiti</strong> ({data.samheiti})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.samheiti_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.samheiti_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.samheiti_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.samheiti_atviksord
											}
										]
									} />
								</div>

								<div className="col">
									<h5><strong>Andheiti</strong> ({data.andheiti})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.andheiti_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.andheiti_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.andheiti_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.andheiti_atviksord
											}
										]
									} />
								</div>

							</div>

							<div className="row">

								<div className="col">
									<h5><strong>Stiklur</strong> ({data.stikla})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.stikla_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.stikla_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.stikla_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.stikla_atviksord
											}
										]
									} />
								</div>

								<div className="col">
									<h5><strong>Orðastæður</strong> ({data.osamb})</h5>
									<PieChartView data={
										[
											{
												label: 'Nafnorð / nafnliðir',
												value: data.osamb_nafnord
											},
											{
												label: 'Lýsingarorð / lo.liðir',
												value: data.osamb_lysingarord
											},
											{
												label: 'Sagnorð / sagnasambönd',
												value: data.osamb_sagnord
											},
											{
												label: 'Atviksorð / atviksliðir',
												value: data.osamb_atviksord
											}
										]
									} />
								</div>

							</div>

							<table className="table">
								<tbody>
									<tr><td><strong>Hugtök</strong></td><td width="60%"><strong>{data.hugtok} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.hugtok_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.liðir</td><td>{data.hugtok_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasambönd</td><td>{data.hugtok_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.hugtok_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Parasambönd</strong></td><td width="60%"><strong>{data.por} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.por_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.por_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.por_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.por_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Skyldheiti</strong></td><td width="60%"><strong>{data.skyldheiti} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.skyldheiti_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.skyldheiti_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.skyldheiti_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.skyldheiti_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Grannheiti</strong></td><td width="60%"><strong>{data.grannheiti} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.grannheiti_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.grannheiti_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.grannheiti_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.grannheiti_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Samheiti</strong></td><td width="60%"><strong>{data.samheiti} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.samheiti_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.samheiti_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.samheiti_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.samheiti_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Andheiti</strong></td><td width="60%"><strong>{data.andheiti} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.andheiti_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.andheiti_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.andheiti_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.andheiti_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Stiklur</strong></td><td width="60%"><strong>{data.stikla} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.stikla_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.stikla_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.stikla_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.stikla_atviksord} flettur</td></tr>
								</tbody>
							</table>

							<table className="table">
								<tbody>
									<tr><td><strong>Orðastæður</strong></td><td width="60%"><strong>{data.osamb} flettur</strong></td></tr>
									<tr><td>Nafnorð / nafnliðir</td><td>{data.osamb_nafnord} flettur</td></tr>
									<tr><td>Lýsingarorð / lo.-liðir</td><td>{data.osamb_lysingarord} flettur</td></tr>
									<tr><td>Sagnorð / sagnasamb</td><td>{data.osamb_sagnord} flettur</td></tr>
									<tr><td>Atviksorð / atviksliðir</td><td>{data.osamb_atviksord} flettur</td></tr>
								</tbody>
							</table>
						</>
					}

				</div>
			</div>
		}
	</React.Fragment>;
}


export default StadtolurView;
